import { KissNavigationItem } from '@kiss/components/common';

import { navigationIcons } from './navigation-icons';
import { environment } from 'environments/environment';

export const navigation: KissNavigationItem[] = [
  {
    type: 'item',
    translation: 'navigation.home',
    url: '/landing',
    icon: {
      svg: navigationIcons.landing,
    },
  },
  {
    type: 'list',
    translation: 'navigation.productCatalogue',
    icon: {
      svg: navigationIcons.productCatalogue,
    },
    children: [
      {
        type: 'item',
        translation: 'navigation.items',
        url: '/product-catalogue',
        excludeUrls: [
          '/product-catalogue/categories',
          '/product-catalogue/import',
          '/product-catalogue/api-to-cart',
        ],
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.categories',
        url: '/product-catalogue/categories',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.import',
        url: '/product-catalogue/import',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.apiToCart',
        url: '/product-catalogue/api-to-cart',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
    ],
  },
  {
    type: 'list',
    translation: 'navigation.publishing',
    hidden: true,
    icon: {
      svg: navigationIcons.publishing,
    },
    children: [
      {
        type: 'item',
        translation: 'navigation.newPost',
        url: '/publishing/new-post',
        icon: {
          svg: navigationIcons.listItem1,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.shoppingEvent',
        url: '/publishing/shoping-event',
        icon: {
          svg: navigationIcons.listItem1,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.shoppingWithFriends',
        url: '/publishing/shoping-with-firends',
        icon: {
          svg: navigationIcons.listItem1,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.newCollection',
        url: '/publishing/new-collection',
        icon: {
          svg: navigationIcons.listItem1,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.oobleeShot',
        url: '/publishing/ooblee-shot',
        icon: {
          svg: navigationIcons.listItem1,
          iconClass: 'nav-list-item',
        },
      },
    ],
  },

  {
    type: 'list',
    translation: 'navigation.orderManagement',
    icon: {
      svg: navigationIcons.orderManagment,
    },
    children: [
      {
        type: 'item',
        translation: 'navigation.orders',
        url: '/order-management',
        excludeUrls: [
          '/order-management/customer-list',
          '/order-management/notifications',
          '/order-management/messages',
        ],
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.messages',
        url: '/order-management/messages',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.notifications',
        url: '/order-management/notifications',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.customers',
        url: '/order-management/customer-list',

        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.ordersConfirmation',
        url: '/order-management/orders-confirmation',

        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
    ],
  },
  {
    type: 'list',
    translation: 'navigation.profileSettings',
    hidden: true,
    icon: {
      svg: navigationIcons.user,
    },
    children: [
      {
        type: 'item',
        translation: 'navigation.profileCustomization',
        url: '/profile-settings/profile-customization',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.location',
        url: '/profile-settings/location',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.orderOptions',
        url: '/profile-settings/order-options',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.paymentSetup',
        url: '/profile-settings/payment-setup',

        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.deliveryLocationsPricelist',
        url: '/profile-settings/delivery-locations-price-list',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.privacyTermsConditions',
        url: '/profile-settings/terms',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.shopAdministrationTeam',
        url: '/profile-settings/shop-administration',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.deleteBusinessProfile',
        url: '/profile-settings/delete-profile',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
    ],
  },
  {
    type: 'list',
    translation: 'navigation.networking',
    hidden: true,
    icon: {
      svg: navigationIcons.network,
    },
    children: [
      {
        type: 'item',
        translation: 'navigation.oobleeCreators',
        url: '/networking/ooblee-creators',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.inviteFollowers',
        url: '/networking/invite-followers',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.shareSpecialOffers',
        url: '/networking/share-offers',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
    ],
  },
  {
    type: 'list',
    translation: 'navigation.oobleeAdvertising',
    hidden: false,
    icon: {
      svg: navigationIcons.add,
    },
    children: [
      {
        type: 'item',
        translation: 'navigation.advertiseBanner',
        url: '/advertising/advertise-banner',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
        hidden: true,
      },
      {
        type: 'item',
        translation: 'navigation.fomoPosts',
        url: '/ooblee-advertising/fomo-post',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.shoppingNews',
        url: '/advertising-shopping-news',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
        hidden: true,
      },
      {
        type: 'item',
        translation: 'navigation.vouchers',
        url: '/vouchers',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
    ],
  },
  {
    type: 'item',
    translation: 'navigation.myPlan',
    url: '/my-plan/wallet',
    icon: {
      svg: navigationIcons.plan,
    },
  },
  {
    type: 'list',
    translation: 'navigation.myPlan',
    icon: {
      svg: navigationIcons.plan,
    },
    hidden: true,
    children: [
      {
        type: 'item',
        translation: 'navigation.wallet',
        url: '/my-plan/wallet',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.stripeDashboard',
        hidden: true, //!environment?.stripe?.enabled,
        function: () => {
          if (!environment?.stripe?.connect) return;
          window.open(environment?.stripe?.connect, '_blank');
        },
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.paymentSettings',
        url: '/my-plan/payment-setup',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
        hidden: true,
      },
    ],
  },
  {
    type: 'list',
    translation: 'navigation.publishing',
    icon: {
      svg: navigationIcons.calendar,
    },
    hidden: true,
    children: [
      {
        type: 'item',
        translation: 'navigation.shopForm',
        url: '/shopping-with-friends',
        exactMatch: true,
        hidden: true,
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.shoppingWithFriends',
        url: '/shopping-with-friends/events',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.vouchers',
        url: '/vouchers',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
    ],
  },
  {
    type: 'list',
    translation: 'navigation.shopSettings',
    icon: {
      svg: navigationIcons.gear,
    },
    children: [
      {
        type: 'item',
        translation: 'navigation.locationSettings',
        url: '/shop-settings/general',

        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.orderOptionsSettings',
        url: '/shop-settings/order-options',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.localDelivery',
        url: '/shop-settings/local-delivery',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
      {
        type: 'item',
        translation: 'navigation.oobleePay',
        url: '/shop-settings/ooblee-pay',
        icon: {
          svg: navigationIcons.emptyIcon,
          iconClass: 'nav-list-item',
        },
      },
    ],
  },
  {
    type: 'item',
    translation: 'navigation.websiteSettings',
    url: '/shop-settings/website-settings',
    icon: {
      svg: navigationIcons.globe,
    },
    hidden: true,
  },
];
