import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
import { TranslateDirective } from './translate.directive';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeMe from '@angular/common/locales/sr-Latn-ME';
import localeCro from '@angular/common/locales/hr';

registerLocaleData(localeDe);
registerLocaleData(localeMe, 'me');
registerLocaleData(localeCro);

@NgModule({
  declarations: [TranslatePipe, TranslateDirective],
  exports: [TranslatePipe, TranslateDirective],
  providers: [TranslatePipe],
})
export class TranslateModule {}
