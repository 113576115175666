import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { AuthModule } from './auth/auth.module';

const routes: Routes = [
  {
    canLoad: [AuthGuard],
    path: 'landing',
    loadChildren: () => import('./main/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    canLoad: [AuthGuard],
    path: 'product-catalogue',
    data: {
      breadcrumb: {
        label: 'User Management',
        translation: 'navigation.productCatalogue',
        isLink: true,
      },
    },
    loadChildren: () =>
      import('./main/product-catalogue/product-catalogue.module').then(
        (m) => m.ProductCatalogueModule
      ),
  },
  {
    canLoad: [AuthGuard],
    path: 'order-management',
    loadChildren: () =>
      import('./main/order-management/order-management.module').then(
        (m) => m.OrderManagementModule
      ),
  },
  {
    canLoad: [AuthGuard],
    path: 'my-plan',
    loadChildren: () => import('./main/my-plan/my-plan.module').then((m) => m.MyPlanModule),
  },
  // {
  //   canLoad: [AuthGuard],
  //   path: 'shopping-with-friends',
  //   loadChildren: () =>
  //     import('./main/shop-with-friends/shop-with-friends.module').then(
  //       (m) => m.ShopWithFriendsModule
  //     ),
  // },

  {
    canLoad: [AuthGuard],
    path: 'shop-settings',
    loadChildren: () =>
      import('./main/shop-settings/shop-settings.module').then((m) => m.ShopSettingsModule),
  },
  {
    canLoad: [AuthGuard],
    path: 'ooblee-advertising',
    loadChildren: () =>
      import('./main/ooblee-advertising/ooblee-advertising.module').then(
        (m) => m.OobleeAdvertisingModule
      ),
  },
  {
    canLoad: [AuthGuard],
    path: 'vouchers',
    loadChildren: () =>
      import('./main/publishing/vouchers/vouchers.module').then((m) => m.VouchersModule),
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }), AuthModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
